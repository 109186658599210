import Vue from 'vue';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/fr';
import './form.scss';

import { Jodit } from 'jodit/build/jodit';
import 'jodit/build/jodit.css';
/*
     * for Quill 
     *
     *
import Quill from 'quill/dist/quill';
import 'quill/dist/quill.snow.css';
    document.querySelectorAll('form').forEach(form => {
        let rt = form.querySelectorAll(".rich-text");
        if (rt.length) {
            let quills = [];
            rt.forEach(container => {
                quills.push({
                    input: container.querySelector('.rich-text-input'),
                    quill: new Quill(container.querySelector('.rich-text-editor'), {
                       /* modules: {
                            toolbar: [
                                ['bold', 'italic'],
                                ['link', 'blockquote', 'code-block', 'image'],
                                [{
                                    list: 'ordered'
                                }, {
                                    list: 'bullet'
                                }]
                            ]
                        }, 
                        placeholder: 'Veuillez saisir un texte',
                        theme: 'snow'
                    })
                })
            });

            form.onsubmit = function () {
                quills.forEach(q => {
                    q.input.value = q.quill.root.innerHTML;
                });
            };

        }
    }); */


var ready = (callback) => {
    if (document.readyState != "loading") callback();
    else document.addEventListener("DOMContentLoaded", callback);
}

let editors = [];
ready(() => {
   // console.log(Jodit.defaultOptions.buttons);
    let buttonsMin = [ "eraser", "|", "bold", "strikethrough", "underline", "italic", "|", "superscript", "subscript", "|", "ul", "ol"];
    let joditOptions = {
        language: 'fr',
        sizeSM: 545,
        sizeLG: 931,
        buttons: buttonsMin,
        buttonsMD: buttonsMin,
        buttonsSM: buttonsMin,
        buttonsXS: buttonsMin,
        //removeButtons: ['\n', 'font', 'fontsize', 'image', 'file', 'video', 'about'],
        beautifyHTML: false,
        sourceEditor: 'area',
    };
    joditOptions.controls = Jodit.defaultOptions.controls;
    delete joditOptions.controls.ul.list;
    delete joditOptions.controls.ol.list;

    document.querySelectorAll('.rich-text textarea').forEach(ta => {
        let b = buttonsMin;
        let opts = joditOptions;
        if (ta.dataset.joditLinks) {
            b.push('|', 'link');
            opts.buttons = b;
            opts.buttonsMD = b;
            opts.buttonsSM = b;
            opts.buttonsXS = b;
        }
        editors.push(new Jodit(ta, opts));
    });
});


Vue.component('dt-picker', {
    props: ['defaultVal', 'inputAttr', 'inputClass', 'type', 'format', 'partial'],
    data: function () {
        return {
            val: this.defaultVal
        }
    },
    template: '<date-picker v-model="val" :input-attr="inputAttr" :input-class="inputClass" value-type="format" :type="type" :format="format" :partial-update="partial"></date-picker>',
    components: {
        DatePicker,
    }
});

new Vue({
    el: 'div.form',
});
